import React from 'react'
import { useSelector } from "react-redux";

const TopAanbiedingen = () => {

    const { folder_name } = useSelector((state) => state.AdminReducer);

    return (
        <section id="topaanbiedingen">
                <div className='container'>
                    <figure><img src={`https://o-sn.be/kml/test-onepages/${folder_name}/weekendpromo.jpg`}/></figure>
                </div>
        </section>
    )
}

export default TopAanbiedingen