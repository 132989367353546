import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { OsnInputText } from "@identitybuilding/idb-react-ui-elements";
import Icon from "@identitybuilding/idb-react-iconlib";
import axios from "axios";

const EShop = (props) => {
    const { data, translate, lang } = useSelector((state) => state.AdminReducer);

    const [naam, setNaam] = useState("");
    const [tel, setTel] = useState("");
    const [vat, setVat] = useState("");
    const [straat, setStraat] = useState("");
    const [huisnummer, setHuisnummer] = useState("");
    const [boxnummer, setBoxnummer] = useState("");
    const [postCode, setPostCode] = useState("");
    const [gemeente, setGemeente] = useState("");
    const [artikellijst, setArtikellijst] = useState("");
    const [prijslijst, setPrijslijst] = useState("");
    const [algemeen, setAlgemeen] = useState("");
    const [mailLoading, setMailLoading] = useState(false);

    const [error, setError] = useState("");

    const fileInputAL = useRef(null);
    const [fileAL, setFileAL] = useState(null);
    const [base64AL, setBase64AL] = useState(null);

    const handleFileChangeAL = (event) => {
        if (event.target.files[0].name.substring(event.target.files[0].name.length - 4) === '.pdf' || event.target.files[0].name.substring(event.target.files[0].name.length - 5) === '.docx') {
            setFileAL(event.target.files[0]);
            // FILE TO BASE 64--------------------------------------------
            // Create a new FileReader
            const reader = new FileReader();

            // Set the file to be read
            reader.readAsDataURL(event.target.files[0]);

            // Add an event listener to be called when the file is loaded
            reader.onload = () => {
                // The file's text will be printed here
                setBase64AL(reader.result);
            };
        }
        else {
            props.createNotification('error', 'Enkel in .pdf of .docx')
            document.getElementById('cv_file').value = "";
        }
    };


    const fileInputPL = useRef(null);
    const [filePL, setFilePL] = useState(null);
    const [base64PL, setBase64PL] = useState(null);

    const handleFileChangePL = (event) => {
        if (event.target.files[0].name.substring(event.target.files[0].name.length - 4) === '.pdf' || event.target.files[0].name.substring(event.target.files[0].name.length - 5) === '.docx') {
            setFilePL(event.target.files[0]);
            // FILE TO BASE 64--------------------------------------------
            // Create a new FileReader
            const reader = new FileReader();

            // Set the file to be read
            reader.readAsDataURL(event.target.files[0]);

            // Add an event listener to be called when the file is loaded
            reader.onload = () => {
                // The file's text will be printed here
                setBase64PL(reader.result);
            };
        }
        else {
            props.createNotification('error', 'Enkel in .pdf of .docx')
            document.getElementById('cv_file').value = "";
        }
    };


    const mailForm = () => {
        setError('')
        // --------------------------------------------
        let maildata = {
            naam: naam,
            tel: tel,
            vat: vat,
            straat: straat,
            huisnummer: huisnummer,
            boxnummer: boxnummer,
            postCode: postCode,
            gemeente: gemeente,
            artikellijst: base64AL,
            prijslijst: base64PL,
            algemeen: algemeen,
        }
        if (naam === "") { props.createNotification('warning', 'Gelieve een Naam in te vullen'); setError('naam'); }
        else if (tel === "") { props.createNotification('warning', 'Gelieve een Mobiel of Telefoon nummer in te vullen'); setError('tel') }
        else if (vat === "") { props.createNotification('warning', 'Gelieve een BTW nummer in te vullen'); setError('vat') }
        else if (straat === "") { props.createNotification('warning', 'Gelieve een straat in te vullen'); setError('straat') }
        else if (huisnummer === "") { props.createNotification('warning', 'Gelieve een huisnummer in te vullen'); setError('huisnummer') }
        else if (postCode === "") { props.createNotification('warning', 'Gelieve een postCode in te vullen'); setError('postCode') }
        else if (gemeente === "") { props.createNotification('warning', 'Gelieve een gemeente in te vullen'); setError('gemeente') }
        else if (base64AL === null || base64AL === "") { props.createNotification('warning', 'Gelieve een Artikellijst up te loaden'); }
        else if (base64PL === null || base64PL === "") { props.createNotification('warning', 'Gelieve een Prijslijst up te loaden'); }
        
        else {
            setMailLoading(true)
            const headers = { "Content-Type": "application/json" };
            axios.post(`https://management.100procentlokaal.be/core/api/localsupliers/mail/${data.establishment_number}/?lang=${lang}`, {
                headers: headers,
                data: maildata,
            })
                .then(() => {

                })
                .catch(() => {
                    setNaam("")
                    setTel("")
                    setVat("")
                    setStraat("")
                    setHuisnummer("")
                    setBoxnummer("")
                    setPostCode("")
                    setGemeente("")
                    setArtikellijst("")
                    setPrijslijst("")
                    setAlgemeen("")
                    setFileAL("")
                    setBase64AL("")
                    setFilePL("")
                    setBase64PL("")
                    props.createNotification('info', lang === 'nl' ? 'Bedankt, wij nemen zo snel mogelijk contact op!' : 'Merci, nous vous contacterons dès que possible!')
                    setTimeout(() => {
                        window.location.href = window.location.href
                    }, 5000);
                })
        }
    };


    return (
        <section id="lokaleLeveranciers">
            <figure>
                <img className="story" src={require('../library/images/lokaal_dossier_story.jpg').default} alt="Company Logo" />
                <img className="vierkant" src={require('../library/images/lokaal_dossier_vierkant.jpg').default} alt="Company Logo" />
            </figure>
            <div id="form-container">
                <div>
                    <div className="block">
                        <h5 style={{ "alignSelf": "flex-start" }}>Identificatie</h5>
                        <OsnInputText
                            required
                            title={translate('name')}
                            onChange={(e) => { setNaam(e.target.value) }}
                            error={error === 'naam' ? true : false}
                            value={naam}
                        />
                        <OsnInputText
                            required
                            title={translate('telephone')}
                            onChange={(e) => { setTel(e.target.value) }}
                            error={error === 'tel' ? true : false}
                            value={tel}
                        />
                        <OsnInputText
                            required
                            title={translate('vat_number')}
                            onChange={(e) => { setVat(e.target.value) }}
                            error={error === 'vat' ? true : false}
                            value={vat}
                        />
                    </div>
                    <div className="block">
                        <h5 style={{ "alignSelf": "flex-start" }}>Adres</h5>
                        <OsnInputText
                            required
                            title={translate('street')}
                            onChange={(e) => { setStraat(e.target.value) }}
                            error={error === 'straat' ? true : false}
                            value={straat}
                        />
                        <OsnInputText
                            required
                            title={translate('ci_house_number')}
                            onChange={(e) => { setHuisnummer(e.target.value) }}
                            error={error === 'huisnummer' ? true : false}
                            value={huisnummer}
                        />
                        <OsnInputText
                            required
                            title={translate('ci_box')}
                            onChange={(e) => { setBoxnummer(e.target.value) }}
                            value={boxnummer}
                        />
                        <OsnInputText
                            required
                            title={translate('ci_postal_code')}
                            onChange={(e) => { setPostCode(e.target.value) }}
                            error={error === 'postCode' ? true : false}
                            value={postCode}
                        />
                        <OsnInputText
                            required
                            title={translate('ci_municipality')}
                            onChange={(e) => { setGemeente(e.target.value) }}
                            error={error === 'gemeente' ? true : false}
                            value={gemeente}
                        />
                    </div>

                    <h5 style={{ "alignSelf": "flex-start" }}>Artikellijst</h5>
                    <input type="file" ref={fileInputAL} onChange={handleFileChangeAL} className="file" id="cv_file" />

                    <h5 style={{ "alignSelf": "flex-start" }}>Prijslijst</h5>
                    <input type="file" ref={fileInputPL} onChange={handleFileChangePL} className="file" id="cv_file" />


                    <label className={`motivatie`}>
                        <div>
                            <h5 style={{ "alignSelf": "flex-start" }}>Algemene info</h5>

                            <textarea
                                htmlFor="message"
                                id="ja_message"
                                placeholder={'Algemene info'}
                                name="message"
                                onChange={(e) => { setAlgemeen(e.target.value) }}
                                value={algemeen}
                                required
                            />
                        </div>
                    </label>

                    <button id="ja_send" type="submit" onClick={() => !mailLoading && mailForm()}>
                        <Icon name="Mail" />{translate('send')}
                    </button>


                </div>
            </div>
        </section>
    );
};

export default EShop;
