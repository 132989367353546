import React, { useState, useEffect, Fragment } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useHistory } from "react-router-dom";
import Icon from "@identitybuilding/idb-react-iconlib";
import { useDispatch, useSelector } from "react-redux";
import { updateLang } from "../actions";
import auth from "../store/auth";

const Nav = (props) => {
  const sections = document.querySelectorAll("section");
  const navLi = document.querySelectorAll("nav .mainNav ul .PageNav li");
  const dispatch = useDispatch();
  const history = useHistory();
  const [color, setColor] = useState("");

  const { data, lang, modules, translate, colors } = useSelector((state) => state.AdminReducer);

  useEffect(() => {


    if (window.location.href.includes('/recepten')) {
      let li = document.getElementsByClassName('recepten')[0]
      li.classList.add('active')
    }
    if (window.location.href.includes('/fotoalbum')) {
      let li = document.getElementsByClassName('photo_album')[0]
      li.classList.add('active')
    }
    if (window.location.href.includes('/onzetroeven')) {
      let li = document.getElementsByClassName('onzetroeven')[0]
      li.classList.add('active')
    }
    if (window.location.href.includes('/news')) {
      let li = document.getElementsByClassName('news')[0]
      li.classList.add('active')
    }
    if (window.location.href.includes('/broodjes')) {
      let li = document.getElementsByClassName('broodjes')[0]
      li.classList.add('active')
    }
    if (window.location.href.includes('/topaanbiedingen')) {
      let li = document.getElementsByClassName('topaanbiedingen')[0]
      li.classList.add('active')
    }
    if (window.location.href.includes('/lokaleleveranciers')) {
      let li = document.getElementsByClassName('lokaleleveranciers')[0]
      li.classList.add('active')
    }
    if (window.location.href.includes('/traiteur')) {
      let li = document.getElementsByClassName('traiteur')[0]
      li.classList.add('active')
    }
    if (window.location.href.includes('/extra/bereide-gerechten')) {
      let li = document.getElementsByClassName('bereide-gerechten')[0]
      li.classList.add('active')
    }
    // if (window.location.href.includes('/weekendpromo')) {
    //   let li = document.getElementsByClassName('weekendpromo')[0]
    //   li.classList.add('active')
    // }
    if (window.location.href.includes('/superplanner')) {
      let li = document.getElementsByClassName('superplanner')[0]
      li.classList.add('active')
    }
    if (window.location.href.includes('/winkelen-bij-ons')) {
      let li = document.getElementsByClassName('winkelen-bij-ons')[0]
      li.classList.add('active')
    }
    window.addEventListener("scroll", (e) => handleScroll(e));
    return window.removeEventListener("scroll", (e) => handleScroll(e));
  }, [modules]);

  window.onscroll = () => {
    if (!window.location.href.includes('recepten') && !window.location.href.includes('fotoalbum') && !window.location.href.includes('onzetroeven') && 
      !window.location.href.includes('news') && !window.location.href.includes('broodjes') && !window.location.href.includes('topaanbiedingen') &&
      !window.location.href.includes('traiteur') && !window.location.href.includes('bereide-gerechten') && !window.location.href.includes('weekendpromo') &&
      !window.location.href.includes('superplanner') && !window.location.href.includes('winkelen-bij-ons') && !window.location.href.includes('lokaleleveranciers')
      ) {
      var current = "";

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        // if (window.pageYOffset < 639) {
        //   current = section.getAttribute("id");
        // }
        // 92 is the height of your navigations, if that changes you have to change this!
        // Made it double the height here
        if (window.pageYOffset >= sectionTop - 165) {
          current = section.getAttribute("id");
        }
      });
      
      navLi.forEach((li) => {
        li.classList.remove("active");
        if (li.classList.contains(current)) {
          li.classList.add("active");
        }
      });
    }
  };

  const handleScroll = () => {
    let scrollTop = document.documentElement.scrollTop;
    setColor(scrollTop > 0 ? "var(--grey-90)" : null);
  };

  const langHandler = (e) => {
    dispatch(updateLang(e));
  };

  const logout = () => {
    auth.actions.logout();
    history.push("/");
    auth.state.admin = undefined;
  };

  const langBlock = (
    <div className="dropdown">
      <button className="dropbtn">
        {lang === "nl" ? "Nederlands" : "Français"}
      </button>
      <div className="dropdown-content">
        <span onClick={() => window.location.href = window.location.href.replace('lang=fr', 'lang=nl')}>Nederlands</span>
        <span onClick={() => window.location.href = window.location.href.replace('lang=nl', 'lang=fr')}>Français</span>
      </div>
    </div>
  );

  const langBlock2 = (
    <div className={`langBlock2 ${colors.oh_color ? 'oh_colors' : ''}`}>
      <a
        className={`${window.location.href.includes('lang=nl') ? 'active' : ''}`}
        href={window.location.href.replace('lang=fr', 'lang=nl')}
      >
        NL
      </a>
      <span>|</span>
      <a
        className={`${window.location.href.includes('lang=fr') ? 'active' : ''}`}
        href={window.location.href.replace('lang=nl', 'lang=fr')}
      >
        FR
      </a>
    </div>
  );


  return (
    <nav>
      {/* <div className="topNav">{langBlock}</div> */}
      <div
        className={[
          "mainNav",
          props.hover_animation,
          props.background ? "bgNav" : "tNav",
        ].join(" ")}
        style={{ backgroundColor: "white", color: "var(--gray-90)" }}
      >
        <div className="navLogo mobileLogo">
          <Link to={`/?lang=${lang}#Home`}>
            <img src={data.logos.length > 0 ? data.logos[0].logo : ""} />
          </Link>
        </div>
        <div className="mobileNav">
          <Icon name="MenuFill" />
        </div>

        <ul
          className="navItems"
          style={{ textAlign: props.position ? props.position : "right" }}
        >
          <div className="navLogo">

            <Link to={`/?lang=${lang}#Home`}>
              <img src={data.logos.length > 0 ? data.logos[0].logo : ""} />
            </Link>
          </div>
          
          <div className="PageNav">
            {(history.location.pathname === "/" || history.location.pathname === "/fotoalbum" || history.location.pathname === "/recepten" || 
              history.location.pathname === "/onzetroeven" || history.location.pathname === "/news" || history.location.pathname === "/broodjes" ||
              history.location.pathname === "/topaanbiedingen" || history.location.pathname === "/traiteur" || history.location.pathname === "/extra/bereide-gerechten" ||
              history.location.pathname === "/weekendpromo" || history.location.pathname === "/superplanner" || history.location.pathname === "/winkelen-bij-ons" ||
              history.location.pathname === "/lokaleleveranciers" 
              ) && (
              <Fragment>
                {modules.map(
                  (res, index) =>
                    res.active &&
                    res.name === "Home" && res.name !== "Footer" && (
                      <li className={res.alias} key={index}>
                        <Link
                          style={{ color: color }}
                          to={`/?lang=${lang}#${res.name.replace(/\s/g, "").replace(/\-/g, "")}`}
                        >
                          <Icon name="Home" />
                        </Link>
                      </li>
                    )
                )}
                {modules.map((res, index) =>
                  res.active && res.in_nav && res.langs.includes(lang) &&  res.name !== "Home" && res.name !== "Footer" && !res.link && res.new_page === false ? (
                    <li className={res.alias} key={index}>
                      <Link
                        style={{ color: color }}
                        to={`/?lang=${lang}#${res.name.replace(/\s/g, "").replace(/\-/g, "")}`}
                      >
                        {translate(res.title)}
                      </Link>
                    </li>
                  )
                    : res.active && res.in_nav && res.langs.includes(lang) &&  res.link ?
                      <li className={`${res.alias} ${res.highlighted ? 'highlighted' : ''}`} key={index}>
                        <a style={{ color: color }} href={res.link} target={res.target_blank ? '_blank' : "_self"}>
                          {translate(res.title)}
                        </a>
                      </li>
                      : res.active && res.in_nav && res.langs.includes(lang) && res.name !== "Home" && res.name !== "Footer" &&
                      <li className={`${res.alias} ${res.highlighted ? 'highlighted' : ''}`} key={index}>
                        <a style={{ color: color }} href={`/${res.slug}?lang=${lang}`}>
                          {translate(res.title)}
                        </a>
                      </li>
                )}
              </Fragment>
            )}
          </div>
          {langBlock2}
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
