/* 
     ___         _      _   
    / __| __ _ _(_)_ __| |_ 
    \__ \/ _| '_| | '_ \  _|
    |___/\__|_| |_| .__/\__|
                  |_|       
*/

export let activePart = 1;
const monthsString = [
    'januari', 'februari', 'maart', 'april', 'mei', 'juni',
    'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
const d = new Date();
const current_year = d.getFullYear();

let fields = {
    part1_fields: [
        "voornaam",
        "achternaam",
        "email1",
        "email2",
        "gsm",
        "tel",
        "ouders",
        "straat",
        "huisnummer",
        "bus",
        "land",
        "postcode",
        "gemeente"
    ],
    part2_fields: [
        "geboorteplaats",
        "IDKaartNr",
        "RijksRegisterNr"
    ],
    part3_fields: [
        "checkWorkTime",
        "vacationDate"
    ]
};

// Launches when page has loaded
export default function pageHasLoaded() {
    // Do not allow the user to copy paste in e-mail field
    blockPasteOnEmailRepeat();

    // Do not allow special characters on number paste
    formatNumberBeforePaste();

    // Set birthdate field
    setBirthDateField();

    // Check if user is set to student or not
    radioJobStudentCheck();

    // Set first vacation period
    setVacationDatePeriod();

    applicantAgreed();
    showPart(1);

    activateDeactivateVacation();
}


// Displays the user name in the title
export function displayName() {
    const voornaam = document.getElementsByName('voornaam')[0].value
    document.getElementById('applicant').innerHTML = voornaam;
}

// When a user clicks on "Ga verder",
// we first check if the user is allowed to continue
export function goToNext() {

    const part_fields = fields[`part${activePart}_fields`];
    const form_message = document.getElementsByClassName("formMessage")[0];
    let error = false;
    if (part_fields.length > 0) {
        for (let i = 0; i < part_fields.length; i++) {
            let item_class = document.getElementsByClassName(part_fields[i])[0];
            fieldValidatorHandler(part_fields[i]);
    
            if (item_class.classList.contains("error")) { error = true; }
            if (part_fields.length === (i + 1)) {
                if (error) {
                    let message = "";
                    if (error) { message = "Gelieve de verplichte velden correct in te vullen"; }
                    form_message.innerHTML = message;
                    form_message.style.opacity = 1;
                    setTimeout(function () { form_message.style.opacity = 0; }, 2000);
                }
                else { showNextPrev(activePart + 1); }
            }
        }
    }
    else { showNextPrev(activePart + 1); }
}

// Go to part
export function goToPrev() { showNextPrev(activePart - 1); }

// Show next or prev part
export function showNextPrev(x) {
    let newActivePart = x;
    let tabs = document.getElementsByClassName("viewTab");

    for (let i = 0; i < tabs.length; i++) {
        if ((i + 1) === newActivePart) { tabs[i].classList.add("active"); }
        else { tabs[i].classList.remove("active"); }
    }

    setHeight(newActivePart);
    disableNextPrevButton(newActivePart);

    activePart = newActivePart;
}

// Activate or disable the buttons
export function disableNextPrevButton(part) {

    let totalParts = document.getElementsByClassName("part").length;
    let go_back_button = document.getElementsByClassName("button")[0];
    let go_next_button = document.getElementsByClassName("button")[1];
    if (part === 1) {
        go_back_button.classList.add("disabled");
        go_next_button.classList.remove("disabled");
    }
    else if (part === totalParts) {
        go_back_button.classList.remove("disabled");
        go_next_button.classList.add("disabled");
    }
    else {
        go_back_button.classList.remove("disabled");
        go_next_button.classList.remove("disabled");
    }
}

// Change the height of the div of the content based on the content height
export function setHeight(x) {
    let content = document.getElementsByClassName("content");
    let height = content[x - 1].clientHeight;
    let part = document.getElementById(`part0${x}`);
    let view = document.getElementById("view");


    view.style = `transform: translateX(-${part.clientWidth * (x - 1)}px); height: ${height}px`;
}

// A general validator
export function fieldValidatorHandler(field) {

    // Part 1 fields
    if (field === "voornaam") { nameValidator("voornaam"); setHeight(activePart); }
    else if (field === "achternaam") { nameValidator("achternaam"); setHeight(activePart); }
    else if (field === "email1") { emailValidator("email1"); setHeight(activePart); }
    else if (field === "email2") { emailRepeatValidator(); setHeight(activePart); }
    else if (field === "gsm") { gsmValidator("gsm"); setHeight(activePart); }
    else if (field === "tel") { telValidator("tel"); setHeight(activePart); }
    else if (field === "ouders") { telValidator("ouders"); setHeight(activePart); }
    else if (field === "straat") { streetValidator("straat"); setHeight(activePart); }
    else if (field === "postcode") { streetValidator("postcode"); setHeight(activePart); }
    else if (field === "gemeente") { streetValidator("gemeente"); setHeight(activePart); }

    // Part 2 fields
    else if (field === "geboorteplaats") { geboorteplaatsValidator("geboorteplaats"); setHeight(activePart); }
    else if (field === "IDKaartNr") { idCardValidator(); setHeight(activePart); }
    else if (field === "RijksRegisterNr") { rijksRegNrValidator(); setHeight(activePart); }

    // Part 3 fields
    else if (field === "checkWorkTime") { workTimeValidator(); setHeight(activePart); }
}

// Sets the birthdate field
export function setBirthDateField(maxAge) {
    const birthdateGroup = document.getElementsByClassName("birthdateGroup")[0];

    let selectFields = birthdateGroup.children;
    let days = [],
        months = [],
        years = [];

    for (let day = 1; day < 32; day++) days.push(`<option>${day}</option>`)
    // for (let month = 0; month < monthsString.length; month++) months.push(`<option>${monthsString[month]}</option>`)
    for (let month = 0; month < monthsString.length; month++) months.push(`<option>${month + 1}</option>`)

    let start_year = 1930;
    let end_year = 2010;

    if (maxAge) { start_year = current_year - maxAge; }
    for (let year = start_year; year < end_year; year++) years.push(`<option>${year}</option>`)

    selectFields[0].innerHTML = days;
    selectFields[1].innerHTML = months;
    selectFields[2].innerHTML = years;
    selectFields[2].value = maxAge ? start_year : 1955;
}

// Dynamically fill in the date select fields for vacation
export function setVacationDatePeriod() {
    let begindag1 = document.getElementsByName("begindag1")[0];
    let beginmaand1 = document.getElementsByName("beginmaand1")[0];
    let beginjaar1 = document.getElementsByName("beginjaar1")[0];
    let einddag1 = document.getElementsByName("einddag1")[0];
    let eindmaand1 = document.getElementsByName("eindmaand1")[0];
    let eindjaar1 = document.getElementsByName("eindjaar1")[0];
    let begindag2 = document.getElementsByName("begindag2")[0];
    let beginmaand2 = document.getElementsByName("beginmaand2")[0];
    let beginjaar2 = document.getElementsByName("beginjaar2")[0];
    let einddag2 = document.getElementsByName("einddag2")[0];
    let eindmaand2 = document.getElementsByName("eindmaand2")[0];
    let eindjaar2 = document.getElementsByName("eindjaar2")[0];
    let begindag3 = document.getElementsByName("begindag3")[0];
    let beginmaand3 = document.getElementsByName("beginmaand3")[0];
    let beginjaar3 = document.getElementsByName("beginjaar3")[0];
    let einddag3 = document.getElementsByName("einddag3")[0];
    let eindmaand3 = document.getElementsByName("eindmaand3")[0];
    let eindjaar3 = document.getElementsByName("eindjaar3")[0];

    let days = [],
        months = [],
        years = []
    for (let d = 1; d < 32; d++) days.push(`<option>${d}</option>`)
    // for (let m = 0; m < monthsString.length; m++) months.push(`<option>${monthsString[m]}</option>`)
    for (let m = 0; m < monthsString.length; m++) months.push(`<option>${0}${m + 1}</option>`)
    for (let y = Number(current_year); y < (Number(current_year) + 11); y++) years.push(`<option>${y}</option>`)


    begindag1.innerHTML = days;
    beginmaand1.innerHTML = months;
    beginjaar1.innerHTML = years;

    einddag1.innerHTML = days;
    eindmaand1.innerHTML = months;
    eindmaand1.value = "februari";
    eindjaar1.innerHTML = years;

    begindag2.innerHTML = days;
    begindag2.value = (Number(begindag1.value) + 1).toString();
    beginmaand2.innerHTML = months;
    beginmaand2.value = "februari";
    beginjaar2.innerHTML = years;

    einddag2.innerHTML = days;
    einddag2.value = begindag2.value;
    eindmaand2.innerHTML = months;
    eindmaand2.value = "maart";
    eindjaar2.innerHTML = years;

    begindag3.innerHTML = days;
    begindag3.value = (Number(begindag2.value) + 1).toString();
    beginmaand3.innerHTML = months;
    beginmaand3.value = "april";
    beginjaar3.innerHTML = years;

    einddag3.innerHTML = days;
    einddag3.value = begindag3.value;
    eindmaand3.innerHTML = months;
    eindmaand3.value = "mei";
    eindjaar3.innerHTML = years;
}

// Adds a second vacation period for the user
export function addPeriod() {
    let periode = document.getElementsByClassName("vacationDateExtra")[0];
    let button = document.getElementsByClassName("periodeKnop")[0];

    if (periode.style.display === "none") {
        periode.style.display = "flex";
        button.innerHTML = "Verwijder toegevoegde periode";
    }
    else {
        periode.style.display = "none";
        button.innerHTML = "Voeg periode toe";
    }
    setHeight(activePart);
    datePeriodValidator();
}

// Adds a third vacation period for the user
export function addPeriod2() {
    let periode = document.getElementsByClassName("vacationDateExtra2")[0];
    let button = document.getElementsByClassName("periodeKnop")[1];

    if (periode.style.display === "none") {
        periode.style.display = "flex";
        button.innerHTML = "Verwijder toegevoegde periode";
    }
    else {
        periode.style.display = "none";
        button.innerHTML = "Voeg nog een periode toe";
    }
    setHeight(activePart);
    datePeriodValidator();
}

export function blockTab(evt) { if (evt.keyCode === 9) evt.preventDefault(); }

/*

    FIELD VALIDATORS

*/

// Validates the "voornaam" and "naam" input field
export function nameValidator(naam) {
    const naam_class = document.getElementsByClassName(naam)[0];
    const naam_field = document.getElementsByName(naam)[0];
    const error = document.getElementsByClassName(`${naam}-error`)[0];
    
    if (naam_field.value.length < 2) {
        naam_class.classList.add("error");
        naam_class.classList.remove("success");
        error.innerHTML = `Een ${naam} heeft minstens 2 karakters`;
    }
    else {
        naam_class.classList.remove("error");
        naam_class.classList.add("success");
        error.innerHTML = "";
    }
    setHeight(activePart);
}

// Checks that only letters and special characters can be used in the input field
export function charValidator(evt) {

    const ASCIICode = (evt.which) ? evt.which : evt.keyCode

    if (
        // Space
        ASCIICode === 32 ||
        // Round brackets
        (ASCIICode === 40 || ASCIICode === 41) ||
        // Hyphen
        ASCIICode === 45 ||
        // For uppercase
        (ASCIICode >= 65 && ASCIICode <= 90) ||
        // For lowercase
        (ASCIICode >= 97 && ASCIICode <= 122) ||
        // For special characters
        (ASCIICode >= 192 && ASCIICode <= 253)
    ) return true;
    return false;
}

// Validates the "email1" input field
export function emailValidator(mail) {
    const email1_class = document.getElementsByClassName(mail)[0];
    const email1_field = document.getElementsByName(mail)[0];
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = regex.test(String(email1_field.value).toLowerCase());
    const error = document.getElementsByClassName(`${mail}-error`)[0];

    if (!email1_field.value) {
        email1_class.classList.add("error");
        email1_class.classList.remove("success");
        error.innerHTML = "Gelieve een e-mail adres in te vullen";
    }
    else {
        if (!isValid) {
            email1_class.classList.add("error");
            email1_class.classList.remove("success");
            error.innerHTML = "Dit e-mail adres is niet geldig";
        }
        else {
            email1_class.classList.remove("error");
            email1_class.classList.add("success");
            error.innerHTML = "";
        }
    }
    setHeight(activePart);
}

// Validates if the second e-mail address is equal to the first
export function emailRepeatValidator() {
    const email2_class = document.getElementsByClassName("email2")[0];
    const email1_field = document.getElementsByName("email1")[0];
    const email2_field = document.getElementsByName("email2")[0];
    const error = document.getElementsByClassName("email2-error")[0];

    if (
        email2_field.value === "" ||
        (email1_field.value !== email2_field.value)
    ) {
        email2_class.classList.add("error");
        email2_class.classList.remove("success");
        error.innerHTML = "De e-mail adressen komen niet overeen";
    }
    else {
        email2_class.classList.remove("error");
        email2_class.classList.add("success");
        error.innerHTML = "";
    }
    setHeight(activePart);
}

// Avoid copy pasting in the email repeat input field
export function blockPasteOnEmailRepeat() {
    const email2_class = document.getElementsByClassName("email2")[0];
    email2_class.onpaste = function (e) { e.preventDefault(); }
}

// Remove special chars on paste in number field
export function formatNumberBeforePaste() {
    const gsm_field = document.getElementsByName("gsm")[0];
    gsm_field.onpaste = function (e) {

        let clipboardData = e.clipboardData || window.clipboardData;
        let pastedData = clipboardData.getData('Text');
        if (pastedData.replace(/[^0-9a-zA-Z]+/g, "").length > 10) { e.preventDefault(); }

        setTimeout(function () { return telFormat("focus", "gsm"), 300 });
    }

    const tel_field = document.getElementsByName("tel")[0];
    tel_field.onpaste = function (e) {

        let clipboardData = e.clipboardData || window.clipboardData;
        let pastedData = clipboardData.getData('Text');
        if (pastedData.replace(/[^0-9a-zA-Z]+/g, "").length > 10) { e.preventDefault(); }

        setTimeout(function () { return telFormat("focus", "tel"), 300 });
    }

    const ouders_field = document.getElementsByName("ouders")[0];
    ouders_field.onpaste = function (e) {

        let clipboardData = e.clipboardData || window.clipboardData;
        let pastedData = clipboardData.getData('Text');
        if (pastedData.replace(/[^0-9a-zA-Z]+/g, "").length > 10) { e.preventDefault(); }

        setTimeout(function () { return telFormat("focus", "ouders"), 300 });
    }
}

// Validates the "gsm" input field
export function gsmValidator(tel) {
    const tel_field = document.getElementsByName(tel)[0];
    const tel_class = document.getElementsByClassName(tel)[0];
    const tel_prefix = tel_field.value.toString();
    const error = document.getElementsByClassName(`${tel}-error`)[0];
    let length =
        tel_prefix.substring(0, 1) === "0" ? 9
            : tel_prefix.substring(0, 2) === "32" ? 10
                : 9;

    const tel_prefix_valid =
        tel_prefix.substring(0, 2) === "04" ? true
            : tel_prefix.substring(0, 2) === "32" ? true
                : tel_prefix.substring(0, 3) === "+32" ? true
                    : false;

    if (tel_field.value === "") {
        tel_class.classList.add("error");
        tel_class.classList.remove("success");
        error.innerHTML = "GSM-nummer invullen is verplicht";
    }
    else if (!tel_prefix_valid) {
        tel_class.classList.add("error");
        tel_class.classList.remove("success");
        error.innerHTML = "GSM-nummers beginnen met \"04\" of \"32\"";
    }
    else if (tel_field.value.length < length) {
        tel_class.classList.add("error");
        tel_class.classList.remove("success");
        error.innerHTML = `GSM-nummer is te kort`;
    }
    else {
        tel_class.classList.remove("error");
        tel_class.classList.add("success");
        error.innerHTML = "";
    }
    setHeight(activePart);
}

// Validates the "tel" and "ouders" input field
export function telValidator(tel) {
    const tel_field = document.getElementsByName(tel)[0];
    const tel_class = document.getElementsByClassName(tel)[0];
    const tel_prefix = tel_field.value.toString();
    const error = document.getElementsByClassName(`${tel}-error`)[0];
    const intern = document.getElementsByClassName("internInput")[0].checked;

    const tel_prefix_valid =
        tel_prefix.substring(0, 1) === "0" ? true
            : tel_prefix.substring(0, 2) === "32" ? true
                : tel_prefix.substring(0, 3) === "+32" ? true
                    : false;

    let length =
        tel_prefix.substring(0, 1) === "0" ? 9
            : tel_prefix.substring(0, 2) === "32" ? 10
                : tel_prefix.substring(0, 3) === "+32" ? 10
                    : 9;

    if (!tel_prefix_valid && tel_field.value !== "") {
        tel_class.classList.add("error");
        tel_class.classList.remove("success");
        error.innerHTML = "Telefoonnummers beginnen met \"0\" of \"32\"";
    }
    else if (tel_field.value !== "" && tel_field.value.length < length) {
        tel_class.classList.add("error");
        tel_class.classList.remove("success");
        error.innerHTML = `Telefoonnummer is te kort`;
    }
    else if ((tel === "ouders") && (tel_field.value === "") && intern) {
        tel_class.classList.add("error");
        tel_class.classList.remove("success");
        error.innerHTML = `Telefoonnummer van ouders of ICE is verplicht voorstudenten`;
    }
    else {
        tel_class.classList.remove("error");
        tel_class.classList.add("success");
        error.innerHTML = "";
    }
    setHeight(activePart);
}

/* 
Validates that the user can only type numbers for the following input fields:
  - "gsm"
  - "tel"
  - "ouders"
*/

export function telNumberValidator(evt, tel) {
    const tel_field = document.getElementsByName(tel)[0];
    let value = tel_field.value.toString();
    let length =
        tel === "gsm" ?
            value.substring(0, 2) === "32" ? 11
                : 10
            : (tel === "tel" || tel === "ouders") &&
                value.substring(0, 2) === "32" ? 10
                : 9

    if (tel_field.value.length === length) { return false }
    else {
        const ASCIICode = (evt.which) ? evt.which : evt.keyCode
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
        return true;
    }
}

/*
gsm
0470 56 39 01 (10)
+32 4 70 56 39 01 (11)

tel
02 532 53 30 (9)
32 2 532 53 30 (10)

*/

export function telFormat(action, tel) {
    const tel_field = document.getElementsByName(tel)[0];
    let value = tel_field.value.toString();
    let new_value = "";
    let prefix = value.substring(0, 2) === "32" ? "32" : "";

    if (action === "focus") {
        new_value = tel_field.value.replace(/[^0-9a-zA-Z]+/g, "");
        tel_field.value = new_value;
    }
    else {
        for (let i = 0; i < tel_field.value.length; i++) {
            if (tel === "gsm") {
                if (prefix === "32") {
                    if (i === 0) { new_value += "+"; }
                    else if (i === 2 || i === 3 || i === 5 || i === 7 || i === 9) new_value += " ";
                }
                else { if (i === 4 || i === 6 || i === 8) new_value += " "; }
            }
            else if (tel === "tel" || tel === "ouders") {
                if (prefix === "32") {
                    if (i === 0) { new_value += "+"; }
                    else if (i === 2 || i === 3 || i === 6 || i === 8) new_value += " ";
                }
                else { if (i === 2 || i === 5 || i === 7) new_value += " "; }
            }
            new_value += tel_field.value[i];
        }
        tel_field.value = new_value;
    }
}

// Validates the "straat" and "gemeente" input field
export function streetValidator(address_type) {
    const address_type_class = document.getElementsByClassName(address_type)[0];
    const address_type_field = document.getElementsByName(address_type)[0];
    const error = document.getElementsByClassName(`${address_type}-error`)[0];

    if(address_type === "postcode" && address_type_field.value.length < 4) {
        address_type_class.classList.add("error");
        address_type_class.classList.remove("success");
        error.innerHTML = `Een postcode bestaat uit 4 cijfers`;
    }
    else if (address_type_field.value !== "") {
        if (address_type_field.value.length < 2) {
            address_type_class.classList.add("error");
            address_type_class.classList.remove("success");
            error.innerHTML = `Een ${address_type} heeft minstens 2 karakters`;
        }
        else {
            address_type_class.classList.remove("error");
            address_type_class.classList.add("success");
            error.innerHTML = "";
        }
    }
    else {
        address_type_class.classList.add("error");
        address_type_class.classList.remove("success");
        error.innerHTML = `Het ${address_type} veld moet worden ingevuld`;
    }
    setHeight(activePart);
}

// Validates the "postcode" input field
export function postalNumberValidator(evt) {
    const postal_field = document.getElementsByName("postcode")[0];
    
    if (postal_field.value.length === 4) { return false }
    else {
        const ASCIICode = (evt.which) ? evt.which : evt.keyCode
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
        return true;
    }
}

// Validates the "geboorteplaats" input field
export function geboorteplaatsValidator() {
    const geboorteplaats_class = document.getElementsByClassName("geboorteplaats")[0];
    const geboorteplaats_field = document.getElementsByName("geboorteplaats")[0];
    const error = document.getElementsByClassName("geboorteplaats-error")[0];

    if (geboorteplaats_field.value === "") {
        geboorteplaats_class.classList.add("error");
        geboorteplaats_class.classList.remove("success");
        error.innerHTML = `Geboorteplaats is verplicht in te vullen`;
    }
    else if (geboorteplaats_field.value.length < 2) {
        geboorteplaats_class.classList.add("error");
        geboorteplaats_class.classList.remove("success");
        error.innerHTML = `Een geboorteplaats heeft minstens 2 karakters`;
    }
    else {
        geboorteplaats_class.classList.remove("error");
        geboorteplaats_class.classList.add("success");
        error.innerHTML = "";
    }
    setHeight(activePart);
}

// Validates the "IDKaartNr" input field
export function idCardValidator() {
    const IDKaartNr_class = document.getElementsByClassName("IDKaartNr")[0];
    const IDKaartNr_field = document.getElementsByName("IDKaartNr")[0];
    const error = document.getElementsByClassName("IDKaartNr-error")[0];
    const value = IDKaartNr_field.value;
    let new_value = "";

    // If the identity card number is 14 (12 characters long + the two hyphens)
    // then we won't update the card number anymore
    if (value.length !== 14) {
        if (value.replace(/[^0-9a-zA-Z]+/g, "").length === 12) {
            for (let i = 0; i < value.length; i++) {
                new_value += value[i];
                if (i === 2 || i === 9) { new_value += '-'; }
            }
            IDKaartNr_field.value = new_value;
            IDKaartNr_class.classList.remove("error");
            IDKaartNr_class.classList.add("success");
            error.innerHTML = "";
        }
        else {
            IDKaartNr_class.classList.add("error");
            IDKaartNr_class.classList.remove("success");
            error.innerHTML = "Een identiteitskaart nummer bestaat uit 12 cijfers";
        }
    }
    setHeight(activePart);
}

// Removes the special characters when focusing on the "IDKaartNr" input field
export function idCardValidatorFocus() {
    const IDKaartNr_field = document.getElementsByName("IDKaartNr")[0];
    IDKaartNr_field.value = IDKaartNr_field.value.replace(/[^0-9a-zA-Z]+/g, "");
}

// Validates that the "IDKaartNr" input field only receives numbers
export function idCardNumberValidator(evt) {
    const idCard = document.getElementsByName("IDKaartNr")[0];
    const ASCIICode = (evt.which) ? evt.which : evt.keyCode;
    if (idCard.value.length >= 12) { return false }
    else if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
}

// Validates the "RijksRegisterNr" input field
export function rijksRegNrValidator() {
    const rijksRegNr_class = document.getElementsByClassName("RijksRegisterNr")[0];
    const rijksRegNr_field = document.getElementsByName("RijksRegisterNr")[0];
    const error = document.getElementsByClassName("RijksRegisterNr-error")[0];
    const value = rijksRegNr_field.value;
    let new_value = "";

    // If the registry number is 15 (11 characters long + 4 special characters)
    // then we won't update the card number anymore
    if (value.length !== 15) {
        if (value.replace(/[^0-9a-zA-Z]+/g, "").length === 11) {
            for (let i = 0; i < value.length; i++) {
                new_value += value[i];
                if (i === 1 || i === 3 || i === 8) { new_value += "."; }
                else if (i === 5) { new_value += "-"; }
            }
            rijksRegNr_field.value = new_value;
            rijksRegNr_class.classList.remove("error");
            rijksRegNr_class.classList.add("success");
            error.innerHTML = "";
        }
        else {
            rijksRegNr_class.classList.add("error");
            rijksRegNr_class.classList.remove("success");
            error.innerHTML = "Een rijksregister nummer bestaat uit 11 cijfers";
        }
    }
    setHeight(activePart);
}

// Removes the special characters when focusing on the "RijksRegisterNr" input field
export function rijksRegNrValidatorFocus() {
    const rijksRegNr_field = document.getElementsByName("RijksRegisterNr")[0];
    rijksRegNr_field.value = rijksRegNr_field.value.replace(/[^0-9a-zA-Z]+/g, "");
}

// Validates that the "RijksRegisterNr" input field only receives numbers
export function rijksRegNrNumberValidator(evt) {
    const idCard = document.getElementsByName("RijksRegisterNr")[0];
    const ASCIICode = (evt.which) ? evt.which : evt.keyCode;

    if (idCard.value.length >= 11) { return false }
    else if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
}

// Validates the checkbox fields that check where the user wants to work
export function workTimeValidator() {
    const workTime_class_field = document.getElementsByClassName("checkWorkTime");
    const error = document.getElementsByClassName("checkWorkTime-error")[0];

    let workTime_check = false;

    for (let i = 0; i < workTime_class_field.length; i++) {
        const item = workTime_class_field[i];

        if (item.checked) workTime_check = true;
        if (workTime_class_field.length === (i + 1)) {
            if (!workTime_check) {
                for (let j = 0; j < workTime_class_field.length; j++) {
                    workTime_class_field[j].classList.add("error");
                    workTime_class_field[j].classList.remove("success");
                }
                error.innerHTML = "Gelieve minstens 1 checkbox aan te duiden";
            }
            else {
                for (let j = 0; j < workTime_class_field.length; j++) {
                    workTime_class_field[j].classList.remove("error");
                    workTime_class_field[j].classList.add("success");
                }
                error.innerHTML = "";
            }
        }
    }
    setHeight(activePart);
}

// Check if user is jobstudent
export function radioJobStudentCheck() {
    const value = document.getElementsByClassName("internInput")[0].checked;
    const target = document.getElementsByClassName("choiceInternJob")[0];
    const target_2 = document.getElementsByClassName("ouders")[0];

    if (value) {
        target.style.display = "block";
        target_2.style.display = "block";
        setBirthDateField(25);
        fields.part3_fields = [
            "checkWorkTime",
            "vacationDate"
        ];
    }
    else {
        target.style.display = "none";
        target_2.style.display = "none";
        setBirthDateField();
        fields.part3_fields = [];
    }
    setHeight(activePart);
}

// If the user selects the "vacation" checkbox, then another block should be shown
export function activateDeactivateVacation() {
    const checked = document.getElementsByName("Vakanties")[0].checked;
    const target = document.getElementsByClassName("choiceVacationPeriod")[0];

    if (checked) target.style.display = "block";
    else target.style.display = "none";

    setHeight(activePart);
    datePeriodValidator();
}

// Validates if the user has correctly set the vacation dates
export function datePeriodValidator() {

    let begindag1 = document.getElementsByName("begindag1")[0];
    let beginmaand1 = document.getElementsByName("beginmaand1")[0];
    let beginjaar1 = document.getElementsByName("beginjaar1")[0];
    let einddag1 = document.getElementsByName("einddag1")[0];
    let eindmaand1 = document.getElementsByName("eindmaand1")[0];
    let eindjaar1 = document.getElementsByName("eindjaar1")[0];
    let begindag2 = document.getElementsByName("begindag2")[0];
    let beginmaand2 = document.getElementsByName("beginmaand2")[0];
    let beginjaar2 = document.getElementsByName("beginjaar2")[0];
    let einddag2 = document.getElementsByName("einddag2")[0];
    let eindmaand2 = document.getElementsByName("eindmaand2")[0];
    let eindjaar2 = document.getElementsByName("eindjaar2")[0];
    let begindag3 = document.getElementsByName("begindag3")[0];
    let beginmaand3 = document.getElementsByName("beginmaand3")[0];
    let beginjaar3 = document.getElementsByName("beginjaar3")[0];
    let einddag3 = document.getElementsByName("einddag3")[0];
    let eindmaand3 = document.getElementsByName("eindmaand3")[0];
    let eindjaar3 = document.getElementsByName("eindjaar3")[0];

    let beginDate1 = new Date(`${beginjaar1.value}-${Number(monthsString.indexOf(beginmaand1.value))}-${begindag1.value}`);
    let endDate1 = new Date(`${eindjaar1.value}-${Number(monthsString.indexOf(eindmaand1.value))}-${einddag1.value}`);
    let beginDate2 = new Date(`${beginjaar2.value}-${Number(monthsString.indexOf(beginmaand2.value))}-${begindag2.value}`);
    let endDate2 = new Date(`${eindjaar2.value}-${Number(monthsString.indexOf(eindmaand2.value))}-${einddag2.value}`);
    let beginDate3 = new Date(`${beginjaar3.value}-${Number(monthsString.indexOf(beginmaand3.value))}-${begindag3.value}`);
    let endDate3 = new Date(`${eindjaar3.value}-${Number(monthsString.indexOf(eindmaand3.value))}-${einddag3.value}`);

    let vacationDate = document.getElementsByClassName("vacationDate")[0];
    let vacationDateExtra = document.getElementsByClassName("vacationDateExtra")[0];
    let vacationDateExtra2 = document.getElementsByClassName("vacationDateExtra2")[0];
    const error = document.getElementsByClassName("vacationDate-error")[0];

    if (beginDate1 >= endDate1) {
        vacationDate.classList.add("error");
        vacationDate.classList.remove("success");
        error.innerHTML = "Begindatum kan niet later starten dan einddatum";
    }
    if (endDate1 <= beginDate1) {
        vacationDate.classList.add("error");
        vacationDate.classList.remove("success");;
        error.innerHTML = "Begindatum kan niet later starten dan einddatum";
    }
    else {
        vacationDate.classList.remove("error");
        vacationDate.classList.add("success");
        error.innerHTML = "";
    }

    if (vacationDateExtra.style.display !== "none") {
        vacationDate.classList.add("error");
        vacationDate.classList.remove("success");
        vacationDateExtra.classList.add("error");
        vacationDateExtra.classList.remove("success");
        vacationDateExtra2.classList.add("error");
        vacationDateExtra2.classList.remove("success");
        if (beginDate2 <= endDate1) { error.innerHTML = "Begindatum van tweede periode moet na einddatum eerste periode starten"; }
        else if (endDate2 <= beginDate2) { error.innerHTML = "Einddatum van tweede periode moet later dan begindatum tweede periode starten"; }
        else if (beginDate2 > endDate2) { error.innerHTML = "Begindatum van tweede periode moet voor einddatum tweede periode starten"; }
        else if (beginDate1 > beginDate2) { error.innerHTML = "Tweede periode moet na de eerste periode starten"; }
        else {
            vacationDate.classList.remove("error");
            vacationDate.classList.add("success");
            vacationDateExtra.classList.remove("error");
            vacationDateExtra.classList.add("success");
            vacationDateExtra2.classList.remove("error");
            vacationDateExtra2.classList.add("success");
            error.innerHTML = "";
        }
    }
    if (vacationDateExtra2.style.display !== "none") {
        vacationDate.classList.add("error");
        vacationDate.classList.remove("success");
        vacationDateExtra.classList.add("error");
        vacationDateExtra.classList.remove("success");
        vacationDateExtra2.classList.add("error");
        vacationDateExtra2.classList.remove("success");
        if (beginDate3 <= endDate2) { error.innerHTML = "Begindatum van derde periode moet na einddatum tweede periode starten"; }
        else if (endDate3 <= beginDate3) { error.innerHTML = "Einddatum van derde periode moet later dan begindatum derde periode starten"; }
        else if (beginDate3 > endDate3) { error.innerHTML = "Begindatum van derde periode moet voor einddatum derde periode starten"; }
        else if (beginDate2 > beginDate3) { error.innerHTML = "Tweede periode moet na de derde periode starten"; }

        else if (beginDate3 <= endDate1) { error.innerHTML = "Begindatum van derde periode moet na einddatum eerste periode starten"; }
        else if (beginDate1 > beginDate3) { error.innerHTML = "Derde periode moet na de eerste periode starten"; }
        else {
            vacationDate.classList.remove("error");
            vacationDate.classList.add("success");
            vacationDateExtra.classList.remove("error");
            vacationDateExtra.classList.add("success");
            vacationDateExtra2.classList.remove("error");
            vacationDateExtra2.classList.add("success");
            error.innerHTML = "";
        }
    }
    setHeight(activePart);
}





// Show or hide content based on certain choices
export function applicantAgreed() {
    const value = document.getElementsByName('chkAgree')[0].checked;
    const target = document.getElementById('ok');
    if (value) {
        target.className = 'active';
        target.disabled = false;
        target.innerHTML = 'Verzenden';
    }
    else {
        target.className = '';
        target.disabled = true;
        target.innerHTML = 'Bevestig eerst dat je onze privacy verklaring hebt gelezen';
    }
}


// Check if all rquired fields have been filled in
export function checkRequired() {
    const form = document.getElementsByTagName('form')[0].querySelectorAll('[required]')
    for (let i = 0; i < form.length; i++) {
        const val = form[i].value
        if (val) form[i].classList.remove('error')
        else form[i].classList.add('error')
    }
}


// Show parts depending on navigation
export function showPart(x) {
    let tabs = document.getElementsByClassName('viewTab');
    for (let i = 0; i < tabs.length; i++) {
        if ((i + 1) === x) { tabs[i].classList.add("active"); }
        else { tabs[i].classList.remove("active"); }
    }
    let content = document.getElementsByClassName('content');
    let height = content[x - 1].clientHeight;
    let part = document.getElementById(`part0${x}`);
    let view = document.getElementById('view');

    view.style = `transform: translateX(-${part.clientWidth * (x - 1)}px); height: ${height}`;
    activePart = x;
}

// Show / hide password forget
export function passwordReset() {
    var x = document.getElementsByClassName("loginPassForget")[0];
    if (x.style.display === "none") { x.style.display = "block"; }
    else { x.style.display = "none"; }
}

export function validatePart(x) {
    const required = {
        part1_requirements: [
            "voornaam",
            "achternaam",
            "email1",
            "gsm"
        ],
        part2_requirements: [
            "geboorteplaats",
            "IDKaartNr",
            "RijksRegisterNr"
        ],
        part3_requirements: [
            "geboorteplaats",
            "IDKaartNr",
            "RijksRegisterNr"
        ]
    };

    const part = required[`part${activePart}_requirements`];
    let buttons = document.getElementsByClassName("button");
    let newPart;

    if (part) {
        for (let i = 0; i < part.length; i++) {
            let item = document.getElementsByName(part[i])[0];
            let message = document.getElementsByClassName("formMessage")[0];

            if (!item.value && x === 1) {
                message.innerHTML = "Gelieve alle verplichte velden in te vullen";
                message.style.opacity = 1;
                setTimeout(function () {
                    message.style.opacity = 0;
                }, 2000);
                break;
            }
            else if (item.className.includes("error") && x === 1) {
                message.innerHTML = "Gelieve de verplichte velden correct in te vullen";
                message.style.opacity = 1;
                setTimeout(function () {
                    message.style.opacity = 0;
                }, 2000);
                break;
            }
            if (part.length === (i + 1)) {
                message.innerHTML = "";
                message.style.opacity = 0;
                if (x === 0) {
                    if (activePart === 1) {
                        buttons[0].classList.add("disabled");
                        break;
                    }
                    else {
                        if (activePart > 2) buttons[0].classList.remove("disabled");
                        else buttons[0].classList.add("disabled");
                        buttons[1].classList.remove("disabled");
                        newPart = activePart - 1;
                        activePart = newPart;
                        showPart(newPart);
                    }
                }
                else {
                    if (activePart === 4) {
                        buttons[1].classList.add("disabled");
                        break;
                    }
                    else {
                        buttons[0].classList.remove("disabled");
                        if (activePart < 3) buttons[1].classList.remove("disabled");
                        else buttons[1].classList.add("disabled");
                        newPart = activePart + 1;
                        activePart = newPart;
                        showPart(newPart);
                    }
                }
            }
        }
    }
    else {
        buttons[0].classList.remove("disabled");
        buttons[1].classList.remove("disabled");
        newPart = activePart - 1;
        activePart = newPart;
        showPart(newPart);
    }
}